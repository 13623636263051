/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  depotProducts: [],
  depotReleases: [],
  depotDeliveries: [],
  shopProducts: [],
  shopOrders: [],
  shopPromoCodes: [],
  isLoading: false,
  isLoadingCreateShipment: false,
};

export const getDepotProducts = createAsyncThunk('shop/getDepotProducts', async () => {
  return API.ShopService.getDepotProducts();
});

export const getDepotReleases = createAsyncThunk('shop/getDepotReleases', async () => {
  return API.ShopService.getDepotReleases();
});

export const getDepotDeliveries = createAsyncThunk('shop/getDepotDeliveries', async () => {
  return API.ShopService.getDepotDeliveries();
});

export const addShopDepotProduct = createAsyncThunk('shop/addShopDepotProduct', async payload => {
  return API.ShopService.addShopDepotProduct(payload);
});

export const addShopDepotProductDelivery = createAsyncThunk('shop/addShopDepotProductDelivery', async payload => {
  return API.ShopService.addShopDepotProductDelivery(payload);
});

export const addShopDepotProductRelease = createAsyncThunk('shop/addShopDepotProductRelease', async payload => {
  return API.ShopService.addShopDepotProductRelease(payload);
});

export const getShopProducts = createAsyncThunk('shop/getShopProducts', async () => {
  return API.ShopService.getShopProducts();
});

export const addShopProduct = createAsyncThunk('shop/addShopProduct', async payload => {
  return API.ShopService.addShopProduct(payload);
});

export const getShopOrders = createAsyncThunk('shop/getShopOrders', async () => {
  return API.ShopService.getShopOrders();
});

export const createShipment = createAsyncThunk('shop/createShipment', async payload => {
  return API.ShopService.createShipment(payload);
});

export const sendEmailToCustomerWithInfoThatOrderHasBeenSent = createAsyncThunk(
  'shop/sendEmailToCustomerWithInfoThatOrderHasBeenSent',
  async payload => {
    return API.ShopService.sendEmailToCustomerWithInfoThatOrderHasBeenSent(payload);
  },
);

export const getShopPromoCodes = createAsyncThunk('shop/getShopPromoCodes', async () => {
  return API.ShopService.getShopPromoCodes();
});

export const createShopProductCode = createAsyncThunk('shop/createShopProductCode', async payload => {
  return API.ShopService.createShopProductCode(payload);
});

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {},
  extraReducers: {
    [getShopOrders.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getShopOrders.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać zamówień!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getShopOrders.fulfilled.toString()]: (state, action) => {
      state.shopOrders = action.payload.data;
      state.isLoading = false;
    },
    [getDepotProducts.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getDepotProducts.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać produktów!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getDepotProducts.fulfilled.toString()]: (state, action) => {
      state.depotProducts = action.payload.data;
      state.isLoading = false;
    },
    [getShopProducts.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getShopProducts.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać produktów!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getShopProducts.fulfilled.toString()]: (state, action) => {
      state.shopProducts = action.payload.data;
      state.isLoading = false;
    },
    [getDepotDeliveries.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getDepotDeliveries.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać produktów!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getDepotDeliveries.fulfilled.toString()]: (state, action) => {
      state.depotDeliveries = action.payload.data;
      state.isLoading = false;
    },
    [getDepotReleases.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getDepotReleases.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać produktów!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getDepotReleases.fulfilled.toString()]: (state, action) => {
      state.depotReleases = action.payload.data;
      state.isLoading = false;
    },
    [addShopDepotProduct.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addShopDepotProduct.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać produktu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addShopDepotProduct.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Dodano produkt!',
        placement: 'bottomRight',
      });
    },
    [addShopDepotProductDelivery.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addShopDepotProductDelivery.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addShopDepotProductDelivery.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Dodano!',
        placement: 'bottomRight',
      });
    },
    [addShopDepotProductRelease.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addShopDepotProductRelease.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addShopDepotProductRelease.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Dodano!',
        placement: 'bottomRight',
      });
    },
    [addShopProduct.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addShopProduct.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addShopProduct.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Dodano!',
        placement: 'bottomRight',
      });
    },
    [createShipment.pending.toString()]: state => {
      state.isLoadingCreateShipment = true;
    },
    [createShipment.rejected.toString()]: (state, action) => {
      state.isLoadingCreateShipment = false;
      notification.error({
        message: 'Nie udało się dodać!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [createShipment.fulfilled.toString()]: state => {
      state.isLoadingCreateShipment = false;
      notification.success({
        message: 'Dodano!',
        placement: 'bottomRight',
      });
    },
    [getShopPromoCodes.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getShopPromoCodes.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać kodów produktowych!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getShopPromoCodes.fulfilled.toString()]: (state, action) => {
      state.shopPromoCodes = action.payload.data;
      state.isLoading = false;
    },
  },
});

export const { addSubmessageToPost } = shopSlice.actions;

export default shopSlice.reducer;
