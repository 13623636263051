import { APIService } from './ApiService';

export const getDepotProducts = () => APIService.get('/shop/depot/products');

export const addShopDepotProduct = payload => APIService.post('/shop/depot/products', { ...payload });

export const getDepotReleases = () => APIService.get('/shop/depot/releases');

export const getDepotDeliveries = () => APIService.get('/shop/depot/deliveries');

export const addShopDepotProductDelivery = payload => APIService.post('/shop/depot/deliveries', { ...payload });

export const addShopDepotProductRelease = payload => APIService.post('/shop/depot/releases', { ...payload });

export const getShopProducts = () => APIService.get('/shop/orders/products');

export const addShopProduct = payload => APIService.post('/shop/orders/products', { ...payload });

export const getShopOrders = () => APIService.get('/shop/orders');

export const createShipment = payload => APIService.post('/shop/orders/shipments', { ...payload });

export const getShopPromoCodes = () => APIService.get('/shop/orders/promocodes');

export const createShopProductCode = payload => APIService.post('/shop/orders/promocodes', { ...payload });

export const sendEmailToCustomerWithInfoThatOrderHasBeenSent = payload =>
  APIService.post('/shop/orders/emails/order-sent', { ...payload });
