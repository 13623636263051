/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Row, Col } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MenueItems from './MenueItems';
import TopMenu from './TopMenu';
import { Div, SmallScreenAuthInfo } from './style';
import MobileBottomMenu from './MobileBottomMenu';
import BurgerButton from './BurgerButton';
import NoSubscriptionHeader from './NoSubscriptionHeader';
import NewEditionHeader from './NewEditionHeader';
import AnnouncementHeader from './AnnouncementHeader';
import AuthInfo from '../components/utilities/auth-info/info';
import AnnouncementModal from '../container/courses/overview/AnnouncementModal';

const { darkTheme } = require('../config/theme/themeVariables');

const { Header, Footer, Sider, Content } = Layout;
// const { darkMode } = config;

const ThemeLayout = WrappedComponent => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide } = this.state;
      const { ChangeLayoutMode, rtl, topMenu, user, activePlan, subscriptionsHistory, allSubscriptions } = this.props;

      const left = !rtl ? 'left' : 'right';
      const darkMode = ChangeLayoutMode;

      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };
      const currentDate = moment();
      const endDate = moment('2024-10-15T23:59:59');

      const showAnnouncementHeader =
        currentDate.isBefore(endDate) &&
        user.isActive &&
        user.role === 'STUDENT' &&
        process.env.REACT_APP_THEME === 'KMI';

      let announcementText = '';

      if (currentDate.isSameOrBefore('2024-10-12', 'day')) {
        announcementText = 'Zostań testerem kursu do matury z matematyki';
      } else if (currentDate.isSame('2024-10-13', 'day')) {
        announcementText = 'Ostatnie 3 dni - Zostań testerem kursu do matury z matematyki';
      } else if (currentDate.isSame('2024-10-14', 'day')) {
        announcementText = 'Ostatnie 2 dni - Zostań testerem kursu do matury z matematyki';
      } else if (currentDate.isSame('2024-10-15', 'day')) {
        announcementText = 'Dziś ostatni dzień - Zostań testerem kursu do matury z matematyki';
      }
      const isAnyHeader =
        (user.isActiveSubscriptionPayment &&
          !user.isActiveSubscription &&
          user.role === 'STUDENT' &&
          !user.isActive &&
          user?.hasUserBoughtAnySubscription) ||
        (user.isActiveSubscriptionPayment &&
          user.isActiveSubscription &&
          user.role === 'STUDENT' &&
          user.isActive &&
          activePlan?.status === 'past_due') ||
        (user.paymentType === 'PACKAGES_V1' && !user.isAccountBlockedSoon) ||
        showAnnouncementHeader;

      // const onShowHide = () => {
      //   this.setState({
      //     hide: !hide,
      //   });
      // };

      const footerStyle = {
        height: '32px',
        fontFamily: 'Red Hat Display',
        color: '#202349',
        fontSize: '12px',
        lineHeight: '32px',
        background: 'rgba(255, 255, 255, .90)',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      const SideBarStyle = {
        margin: '50px 0 0 0',
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL;
      const contactUrl = process.env.REACT_APP_CONTACT_URL;

      return (
        <Div darkMode={darkMode}>
          <Layout className="layout">
            <Header
              className="page-main-header"
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <Row className="page-main-header-content-row">
                <Col lg={12} sm={16} xs={18} className="align-center-v navbar-brand header-cols">
                  {!topMenu || window.innerWidth <= 991 ? (
                    <BurgerButton id="burger-button" collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
                  ) : null}
                  <Link
                    className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                    to="/"
                  >
                    <h1 className="logo" style={{ fontSize: window.innerWidth > 400 ? '21px' : '4.5vw' }}>
                      {/* <FeatherIcon icon="book" /> */}
                      {process.env.REACT_APP_NAME}
                    </h1>
                  </Link>
                </Col>

                <Col lg={6} md={0} sm={0} xs={0} className="header-cols">
                  {topMenu && window.innerWidth > 991 && <TopMenu />}
                </Col>

                <Col
                  lg={6}
                  md={8}
                  sm={8}
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className="header-cols"
                >
                  <AuthInfo />
                </Col>
              </Row>
            </Header>
            {showAnnouncementHeader ? (
              <AnnouncementHeader
                activePlan={activePlan}
                user={user}
                text={announcementText}
                buttonText="Sprawdź szczegóły"
              />
            ) : (user.isActiveSubscriptionPayment &&
                !user.isActiveSubscription &&
                user.role === 'STUDENT' &&
                !user.isActive &&
                user?.hasUserBoughtAnySubscription) ||
              (user.isActiveSubscriptionPayment &&
                user.isActiveSubscription &&
                user.role === 'STUDENT' &&
                activePlan?.status === 'past_due' &&
                user.isActive) ? (
              <NoSubscriptionHeader
                activePlan={activePlan}
                subscriptionsHistory={subscriptionsHistory}
                allSubscriptions={allSubscriptions}
                user={user}
              />
            ) : (
              <></>
            )}
            {user.paymentType === 'PACKAGES_V1' && !user.isAccountBlockedSoon && <NewEditionHeader />}
            <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout
              id="layout-wrapper"
              style={{
                marginTop: isAnyHeader ? '30px' : '0px',
              }}
            >
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={darkTheme}>
                  <Sider
                    width={240}
                    id="aside-sider"
                    style={SideBarStyle}
                    collapsed={collapsed}
                    theme={!darkMode ? 'light' : 'dark'}
                  >
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={renderTrackVertical}
                    >
                      <MenueItems
                        topMenu={topMenu}
                        rtl={rtl}
                        toggleCollapsed={toggleCollapsedMobile}
                        darkMode={darkMode}
                        collapsed={collapsed}
                      />
                    </Scrollbars>
                  </Sider>
                </ThemeProvider>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...this.props} />
                </Content>
              </Layout>
            </Layout>
            <Footer className="admin-footer" style={footerStyle}>
              <Row id="footer-row">
                <div>
                  <div className="no-bottom-menu">
                    <span className="admin-footer__copyright">{process.env.REACT_APP_NAME}</span>
                  </div>
                  <div className="mobile-bottom-menu">
                    <MobileBottomMenu darkMode={darkMode} />
                  </div>
                </div>
                <div>
                  <div id="policy-div">
                    <div className="admin-footer__links">
                      <a href={`${privacyPolicyUrl}`}>Polityka prywatności</a>
                      <a href={`${contactUrl}`}>Kontakt</a>
                    </div>
                  </div>
                </div>
              </Row>
            </Footer>
          </Layout>
          <AnnouncementModal user={user} footer={false} />
        </Div>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      ChangeLayoutMode: state.themeLayout.darkMode,
      rtl: state.themeLayout.rtl,
      topMenu: state.themeLayout.topMenu,
      user: state.user.user,
      activePlan: state.subscriptions.activePlan,
      subscriptionsHistory: state.subscriptions.history,
      allSubscriptions: state.subscriptions.allSubscriptions,
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
